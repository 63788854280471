import * as React from 'react'
import Layout from '../components/layout'
import AccessInfo from '../components/accessContactInfo'
import ContactDetails from '../components/contactDetails'
import SectionBreak from '../components/sectionBreak'
import { StaticImage } from 'gatsby-plugin-image'
import '../css/access.css'

const AccessPage = () => {
    return (
        <Layout pageTitle="Access" subTitle="Access" description="How to get to your shamisen lesson.">
            <div className='access-page sub-wrapper'>
                <h2 className='section-header'>Address, Map and Directions</h2>
                <div className='top-details'>
                    <AccessInfo />
                    <ContactDetails />
                </div>

                <iframe title='google-map' className='gmap' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.200797616252!2d139.66413631545828!3d35.62277604078266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f4e9577e6da1%3A0xed8401a44caeab65!2s605%2C%205-ch%C5%8Dme-17-22%20Yakumo%2C%20Meguro%20City%2C%20Tokyo%20152-0023!5e0!3m2!1sen!2sjp!4v1652423597244!5m2!1sen!2sjp" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>

                <SectionBreak sectionBreakText='Where is the door?' />

                <h2 className='section-header'>
                    Directions
                </h2>
                <div className='section-content'>
                    <p>
                        The nearest stations are Toritsu-daigaku Station <span lang="ja">(都立大学駅)</span> on the Toyoko line and Komazawa-Daigaku Station <span lang="ja">(駒沢大学駅)</span> on the Denentoshi Line.
                    </p>
                    <p>
                        Makoto's apartment is on the top floor and the doorway is on the left of the building as you look at it, just up the steps.
                    </p>
                    <p>

                    </p>
                    <div className='static-img-container'>
                        <StaticImage
                            className='normal-flow-img'
                            alt="The front of Makoto's apartmnet building, with a Panasonic day care centre on the ground floor."
                            src='../images/access/apartment.jpg'
                        />
                    </div>
                    <div className='static-img-container'>
                        <StaticImage
                            className='normal-flow-img'
                            alt="The entrance of Makoto's apartmnet building, a doorway at the top of some steps."
                            src='../images/access/doorway.jpg'
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AccessPage