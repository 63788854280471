import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'

const SectionBreak = ({ sectionBreakText }) => {
    return (
        <div className='section-break'>
            <h3>{sectionBreakText}</h3>
            <div className='section-break-img-container'>
                <StaticImage
                    // className='section-break-img'
                    alt='black silhouette of a shamisen'
                    src='../icons/shamisen-3.png'
                />
                <StaticImage
                    // className='section-break-img'
                    alt='black silhouette of a shamisen'
                    src='../icons/shamisen-3.png'
                />
                <StaticImage
                    // className='section-break-img'
                    alt='black silhouette of a shamisen'
                    src='../icons/shamisen-3.png'
                />

            </div>
        </div>
    )
}

export default SectionBreak